import React, { Component } from 'react';
import '../assets/css/header.css';

class Nav extends Component {
  state = {
    scrolled: false,
    logo: true,
    isVisible: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      this.setState({ isVisible: true });
      if (window.innerWidth > 991) {
        this.setState({ logo: false });
      } else {
        this.setState({ logo: true });
      }
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false, logo: true, isVisible: false });
    }
  };

  toggleSidebar = () => {
    const header = document.querySelector('.header');
    header.classList.toggle('active');
  };

  scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

    scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: 'smooth',
      });
  };

  render() {
    return (
      <>
        <div className="header" id="header">
          <nav className="navbar navbar-expand-lg">
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleSidebar}
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div id="navbarSupportedContent">
              <ul className={`navbar-nav ${this.state.scrolled ? 'navbar-nav-scrolled' : ''}`}>
                <li className="nav-item">
                  <button className={`nav-link ${this.state.scrolled ? 'nav-link-scrolled' : ''}`} onClick={() => this.scrollToSection('social')}>
                    {/* Afficher 'logo_white' si scrolled est vrai, sinon afficher 'logo' */}
                    {this.state.logo ? (
                      <img src="../assets/img/logo.png" alt="Logo" className="nav-logo logo-black" draggable="false" />
                    ) : (
                      <img src="../assets/img/logo_white.png" alt="Logo White" className="nav-logo logo-white" draggable="false" />
                    )}
                  </button>
                </li>
                <li className="nav-item">
                  <button className={`nav-link ${this.state.scrolled ? 'nav-link-scrolled' : ''}`} onClick={() => this.scrollToSection('services')}>Services</button>
                  <hr></hr>
                </li>
                <li className="nav-item">
                  <button className={`nav-link ${this.state.scrolled ? 'nav-link-scrolled' : ''}`} onClick={() => this.scrollToSection('skills')}>Compétences</button>
                  <hr></hr>
                </li>
                <li className="nav-item">
                  <button className={`nav-link ${this.state.scrolled ? 'nav-link-scrolled' : ''}`} onClick={() => this.scrollToSection('rate')}>Tarifs</button>
                  <hr></hr>
                </li>
                <li className="nav-item">
                  <button className={`nav-link ${this.state.scrolled ? 'nav-link-scrolled' : ''}`} onClick={() => this.scrollToSection('about')}>À propos</button>
                  <hr></hr>
                </li>
                <li className="nav-item">
                  <button className={`nav-link ${this.state.scrolled ? 'nav-link-scrolled' : ''}`} onClick={() => this.scrollToSection('contact')}>Contact</button>
                  <hr></hr>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        {this.state.isVisible && (
          <button className="scroll-top" onClick={this.scrollToTop}>
            <i class="fa-solid fa-chevron-up"></i>
          </button>
        )}
      </>
    );
  }
}

export default Nav;
