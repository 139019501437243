import React, { Component } from 'react';

import '../assets/css/space.css';

class Space extends Component {
  render() {
    return (
      <>
        <div className='space' id="space">
            <p>
                <i class="fa-solid fa-angles-right"></i>
                {this.props.content}
                <i class="fa-solid fa-angles-left"></i>
            </p>
        </div>
      </>
    )
  }
}

export default Space;