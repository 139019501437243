import React, { Component } from 'react';
import '../assets/css/footer.css';

class Footer extends Component {
  render() {
    return (
      <>
        <div className='footer'>
          <section className='network'>
              <ul>
                <img src="../assets/img/logo.png" alt="Logo" className='nav-logo'/>
                <li><a href="#" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                <li><a href="#" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                <li><a href="#" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
              </ul>
          </section>
          <section className='first'>
            <div className='sub-first'>
              <div className='info'>
                <h4>Développement Web Freelance</h4><hr></hr>
                <p>
                  Développeur Web front & back-end & Webdesigner freelance, je suis à votre disposition pour répondre à tout type de projets de création de sites internet, de développement spécifique ou d'applications web.
                  Passionné par les technologies liées au Web, je mets mes compétences au service de vos besoins dans divers domaines.
                </p>
              </div>
              <div className='contact'>
                <h4>Contact</h4><hr></hr>
                <ul>
                  <li><i class="fa-solid fa-phone"></i><a href="tel:+33766209146"> +33 7 66 20 91 46</a></li>
                  <li><i class="fa-solid fa-envelope"></i><a href="mailto:contact@jw-studio.fr"> contact@jw-studio.fr</a></li>
                  <li><i class="fa-solid fa-link"></i><a href="https://jw-studio.fr"> https://jw-studio.fr</a></li>
                </ul>
              </div>
            </div>
          </section>
          <section className='second'>
            <div className='sub-second'>
              JW-STUDIO © 2024
            </div>
            <div className='sub-second'>
                <a href="http://jw-studio.fr:/mentions-legales">Mentions légales </a> ▪
            </div>
            <div className='sub-second'>
              <small> JORDAN Charly</small>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default Footer;