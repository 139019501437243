import React, { Component } from 'react';

import SliderReact from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/slider.css';

import DrawText from '../utils/DrawText';

class Slider extends Component {
  state = {
    slides: [
      {
        image: '../assets/img/1.jpg',
        title: 'Jordan Web Studio',
        description: 'Développeur informatique freelance',
      },
      {
        image: '../assets/img/2.jpg',
        title: 'Création de sites web',
        description: 'Site vitrine, événementiel, e-commerce',
      },
    ],
    settings: {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000
    }
  };

  render() {
    return (
      <>
        <div className='slider' id="slider">
          <SliderReact {...this.state.settings}>
            {this.state.slides.map((slide, index) => (
              <div key={index} className="slide">
                <img src={slide.image} alt={`Banner ${index + 1}`} className="slide-image slide-zoom" />
                <div className="slide-content">
                  <p><DrawText text={slide.title} /></p>
                  <h1><DrawText text={slide.description} /></h1>
                </div>
              </div>
            ))}
          </SliderReact>
        </div>
      </>
    )
  }
}

export default Slider;