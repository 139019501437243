import React, { Component } from 'react';
import '../assets/css/social.css';

class Social extends Component {
  render() {
    return (
      <>
        <div className='social' id="social">
          <div className='social-icon'>
            <i class="fa-brands fa-facebook-f"></i>
            <i class="fa-brands fa-instagram"></i>
            <i class="fa-brands fa-linkedin"></i>
          </div>
          <div className='social-contact'>
            <a href="tel:+33766209146"><i class="fa-solid fa-phone"></i> <span>+33 7 66 20 91 46</span></a>
            <a href="mailto:contact@jw-studio.fr"><i class="fa-solid fa-envelope"></i> <span>contact@jw-studio.fr</span></a>
          </div>
        </div>
      </>
    )
  }
}

export default Social;