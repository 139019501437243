import React, { Component } from 'react';

import Nav from '../components/Nav';
import Social from '../components/Social';
import Service from '../components/Service';
import Slider from '../components/Slider';
import Rate from '../components/Rate';
import Skills from '../components/Skills';
import About from '../components/About';
import Space from '../components/Space';
import DrawText from '../utils/DrawText';
import Footer from '../layouts/Footer';

class Master extends Component {
  toggleSidebar = () => {
    const header = document.querySelector('.header');
    if (header && header.classList.contains('active')) {
      header.classList.toggle('active');
    }
  };

  render() {
    return (
      <>
        <DrawText/>
        <Social/>
        <Nav/>
        <div className='master' onClick={this.toggleSidebar}>
          <Slider/>
          <Service/>
          <Space content="Ordinateur, tablette ou téléphone, j'adapte votre application sur tous les supports"/>
          <Skills/>
          <Space content="Protégez vos données et votre entreprise avec un service de cybersécurité sur mesure"/>
          <Rate/>
          <About/>
          <Footer/>
        </div>
      </>
    )
  }
}

export default Master;