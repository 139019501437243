import React, { Component } from 'react';

class DrawText extends Component {
  state = {
    draw: '',
    index: 0,
  }

  componentDidMount() {
    this.showTexte();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  showTexte = () => {
    const { index } = this.state;
    const { text } = this.props;
    if (text) {
        if (index < text.length) {
            this.setState({
              draw: this.state.draw + text.charAt(index), 
              index: index + 1
            });
      
            this.timeoutId = setTimeout(this.showTexte, 25);
        }
    }
  };

  render() {
    return (
      <>
        {this.state.draw}
      </>
    );
  }
}

export default DrawText;